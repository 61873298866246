import React from "react";

const FiltroTipo = ({
  eventos,
  filtradoCruzado,
  tiposEvento,
  state,
  setState,
  popUp,
  setPopUp,
}) => {
  const handleClickTipoMenu = () => {
    setPopUp((s) => ({
      fecha: false,
      tipo: !s.tipo,
      area: false,
    }));
  };

  const HandleButtonTipo = (value) => {
    let newTiposSeleccionados;
    const existe = state.tiposSeleccionados.find((x) => x === value);
    if (existe) {
      newTiposSeleccionados = state.tiposSeleccionados.filter(
        (x) => x !== value
      );
    } else {
      newTiposSeleccionados = [...state.tiposSeleccionados, value];
    }
    setState((s) => ({ ...s, tiposSeleccionados: newTiposSeleccionados }));
  };

  const handleButtonLimpiarFiltrosTipo = () => {
    let aux = {
      ...state,
      filtradoXTipo: [],
      tiposSeleccionados: [],
    };

    filtradoCruzado(aux);
  };

  const handleClickAplicarTipo = () => {
    const newEventos = [];

    if (state.tiposSeleccionados.length === 0) {
      let aux = {
        ...state,
        filtradoXTipo: [],
      };

      filtradoCruzado(aux);
    } else {
      eventos.forEach((evento) => {
        if (evento.tipo_evento) {
          if (state.tiposSeleccionados.includes(evento.tipo_evento.Nombre)) {
            const existe = newEventos.find((n) => n.id === evento.id);
            if (!existe) {
              newEventos.push(evento);
            }
          }
        }
      });
      let aux = {
        ...state,
        eventos: [...newEventos],
        filtradoXTipo: [...newEventos],
      };

      filtradoCruzado(aux);
    }
  };
  // console.log("Tipos Evento:", tiposEvento); // Agrega este console.log para ver los eventos  console.log("Eventos:", eventos); // Agrega este console.log para ver los eventos
  return (
    <div className="wrap-filter-fecha">
      <button
        data-w-id="Button 4"
        className="btn-filter-home tipo w-button new-button"
        style={
          popUp
            ? {
                borderColor: "rgb(230, 231, 238)",
                backgroundColor: "rgb(230, 231, 238)",
              }
            : {}
        }
        onClick={handleClickTipoMenu}
      >
        tipo
      </button>
      <div
        className="pop-filter center"
        style={popUp ? { opacity: 1, display: "block" } : {}}
      >
        <div className="w-form">
          <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <div className="wrap-pop-tag">
              {tiposEvento.map((tipo) => (
                <button
                  type="button"
                  className="link-tag new-button"
                  key={tipo.id}
                  onClick={() => HandleButtonTipo(tipo.Nombre)}
                  style={
                    state.tiposSeleccionados.includes(tipo.Nombre)
                      ? {
                          backgroundColor: "rgba(35, 101, 255, 0.2)",
                          color: "#1644e5",
                          fontWeight: 700,
                        }
                      : {}
                  }
                >
                  {tipo.Nombre}
                </button>
              ))}
            </div>
            <div className="wrap-btn-pop">
              <button
                type="button"
                className="new-button-eliminar-filtro"
                onClick={handleButtonLimpiarFiltrosTipo}
              >
                Eliminar el filtro
              </button>
              <input
                type="button"
                value="APLICAR"
                data-wait="Please wait..."
                id="form-tipo-submit"
                className="btn-blue pop w-button new-button"
                onClick={handleClickAplicarTipo}
              />
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltroTipo;
