import React, { useState } from "react"
import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import es from "date-fns/locale/es" //idioma react-dateícker
import ordenarEventos from "../../helpers/ordenarEventos"
registerLocale("es", es) //idioma react-dateícker
setDefaultLocale("es") //idioma react-dateícker

var moment = require("moment")

const FiltroFecha = ({ eventos, state, filtradoCruzado, popUp, setPopUp }) => {
  const [fechaRango, setFechaRango] = useState({
    fechaInicio: new Date(),
    fechaFin: new Date(),
  })

  const handleClickFechaMenu = () => {
    setPopUp(s => ({
      fecha: !s.fecha,
      tipo: false,
      area: false,
    }))
  }

  const eventosOrdenados = ordenarEventos(eventos)

  const handleButtonHoy = () => {
    const hoy = moment()

    const eventosHoy = eventosOrdenados
      .filter(
        e =>
          e.fechas.filter(f =>
            f.Fecha
              ? moment(f.Fecha).isSame(hoy, "day")
              : f.Inicio && f.Fin
              ? hoy.isBetween(moment(f.Inicio), moment(f.Fin), "day", "[]")
              : false
          ).length
      )
      .map(e => e.evento)

    let aux = {
      ...state,
      hoy: true,
      semana: false,
      semanaSiguiente: false,
      eventos: [...eventosHoy],
      filtradoXFecha: [...eventosHoy],
      fechasSeleccionadas: true,
    }

    filtradoCruzado(aux)
  }

  const handleButtonSemana = () => {
    const semanaInicio = moment().startOf("week").hour(0).minute(0).second(0)
    const semanaFin = moment().endOf("week").hour(0).minute(0).second(0)

    const eventosSemana = eventosOrdenados
      .filter(
        e =>
          e.fechas.filter(f =>
            f.Fecha
              ? moment(f.Fecha).isBetween(semanaInicio, semanaFin, "day", "[]")
              : f.Inicio && f.Fin
              ? semanaInicio.isBetween(
                  moment(f.Inicio),
                  moment(f.Fin),
                  "day",
                  "[]"
                ) ||
                semanaFin.isBetween(
                  moment(f.Inicio),
                  moment(f.Fin),
                  "day",
                  "[]"
                ) ||
                moment(f.Inicio).isBetween(
                  semanaInicio,
                  semanaFin,
                  "day",
                  "[]"
                ) ||
                moment(f.Fin).isBetween(semanaInicio, semanaFin, "day", "[]")
              : false
          ).length
      )
      .map(e => e.evento)

    let aux = {
      ...state,
      hoy: false,
      semana: true,
      semanaSiguiente: false,
      eventos: [...eventosSemana],
      filtradoXFecha: [...eventosSemana],
      fechasSeleccionadas: true,
    }

    filtradoCruzado(aux)
  }

  const handleButtonSemanaSiguiente = () => {
    const proximaSemana = moment().add(7, "days")
    const semanaSiguienteInicio = proximaSemana
      .startOf("week")
      .hour(0)
      .minute(0)
      .second(0)
    const semanaSiguienteFin = proximaSemana
      .endOf("week")
      .hour(0)
      .minute(0)
      .second(0)

    const eventosSemanaSiguiente = eventosOrdenados
      .filter(
        e =>
          e.fechas.filter(f =>
            f.Fecha
              ? moment(f.Fecha).isBetween(
                  semanaSiguienteInicio,
                  semanaSiguienteFin,
                  "day",
                  "[]"
                )
              : f.Inicio && f.Fin
              ? semanaSiguienteInicio.isBetween(
                  moment(f.Inicio),
                  moment(f.Fin),
                  "day",
                  "[]"
                ) ||
                semanaSiguienteFin.isBetween(
                  moment(f.Inicio),
                  moment(f.Fin),
                  "day",
                  "[]"
                ) ||
                moment(f.Inicio).isBetween(
                  semanaSiguienteInicio,
                  semanaSiguienteFin,
                  "day",
                  "[]"
                ) ||
                moment(f.Fin).isBetween(
                  semanaSiguienteInicio,
                  semanaSiguienteFin,
                  "day",
                  "[]"
                )
              : false
          ).length
      )
      .map(e => e.evento)

    let aux = {
      ...state,
      hoy: false,
      semana: false,
      semanaSiguiente: true,
      eventos: [...eventosSemanaSiguiente],
      filtradoXFecha: [...eventosSemanaSiguiente],
      fechasSeleccionadas: true,
    }

    filtradoCruzado(aux)
  }

  const handleButtonLimpiarFiltrosFecha = () => {
    let aux = {
      ...state,
      hoy: false,
      semana: false,
      semanaSiguiente: false,
      filtradoXFecha: [],
      fechasSeleccionadas: false,
    }

    filtradoCruzado(aux)
  }

  const handleButtonFechaRango = e => {
    const rangoInicio = moment(fechaRango.fechaInicio)
    const rangoFin = moment(fechaRango.fechaFin)

    if (rangoInicio.isAfter(rangoFin, "day")) {
      let aux = {
        ...state,
        errorFechas: true,
      }

      filtradoCruzado(aux)
    } else {
      const eventosRango = eventosOrdenados
        .filter(
          e =>
            e.fechas.filter(f =>
              f.Fecha
                ? moment(f.Fecha).isBetween(rangoInicio, rangoFin, "day", "[]")
                : f.Inicio && f.Fin
                ? rangoInicio.isBetween(
                    moment(f.Inicio),
                    moment(f.Fin),
                    "day",
                    "[]"
                  ) ||
                  rangoFin.isBetween(
                    moment(f.Inicio),
                    moment(f.Fin),
                    "day",
                    "[]"
                  ) ||
                  moment(f.Inicio).isBetween(
                    rangoInicio,
                    rangoFin,
                    "day",
                    "[]"
                  ) ||
                  moment(f.Fin).isBetween(rangoInicio, rangoFin, "day", "[]")
                : false
            ).length
        )
        .map(e => e.evento)

      let aux = {
        ...state,
        hoy: false,
        semana: false,
        semanaSiguiente: false,
        eventos: [...eventosRango],
        filtradoXFecha: [...eventosRango],
        fechasSeleccionadas: true,
      }

      filtradoCruzado(aux)
    }
  }

  const handleChangeFechaInicio = e => {
    setFechaRango(x => ({ ...x, fechaInicio: e }))
  }

  const handleChangeFechaFin = e => {
    setFechaRango(x => ({ ...x, fechaFin: e }))
  }

  return (
    <div className="wrap-filter-fecha">
      <button
        data-w-id="6731e583-814a-efc9-b29c-f9f7cd7fc085"
        className="btn-filter-home w-button new-button"
        style={
          popUp
            ? {
                borderColor: "rgb(230, 231, 238)",
                backgroundColor: "rgb(230, 231, 238)",
              }
            : {}
        }
        onClick={handleClickFechaMenu}
      >
        fechas
      </button>
      <div
        className="pop-filter"
        style={popUp ? { opacity: 1, display: "block" } : {}}
      >
        <div className="w-form">
          <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <div className="wrap-pop-tag">
              <button
                type="button"
                className="link-tag new-button"
                onClick={handleButtonHoy}
                style={
                  state.hoy
                    ? {
                        backgroundColor: "rgba(35, 101, 255, 0.2)",
                        color: "#1644e5",
                        fontWeight: 700,
                      }
                    : {}
                }
              >
                hoy
              </button>
              <button
                type="button"
                className="link-tag new-button"
                onClick={handleButtonSemana}
                style={
                  state.semana
                    ? {
                        backgroundColor: "rgba(35, 101, 255, 0.2)",
                        color: "#1644e5",
                        fontWeight: 700,
                      }
                    : {}
                }
              >
                esta semana
              </button>
              <button
                type="button"
                className="link-tag new-button"
                onClick={handleButtonSemanaSiguiente}
                style={
                  state.semanaSiguiente
                    ? {
                        backgroundColor: "rgba(35, 101, 255, 0.2)",
                        color: "#1644e5",
                        fontWeight: 700,
                      }
                    : {}
                }
              >
                próxima semana
              </button>
            </div>
            <div className="text-border-pop">
              Ingresa una fecha personalizada:
            </div>
            <div className="flex-interna pop">
              <div className="div-50 pop">
                <label htmlFor="name" className="field-label-pop">
                  INICIO
                </label>

                <DatePicker
                  dateFormat="dd/MM/yy"
                  className="text-field-pop w-input"
                  selected={fechaRango.fechaInicio}
                  onChange={handleChangeFechaInicio}
                />
              </div>
              <div className="div-50 pop">
                <label htmlFor="email" className="field-label-pop">
                  FIN
                </label>
                <DatePicker
                  dateFormat="dd/MM/yy"
                  className="text-field-pop w-input"
                  selected={fechaRango.fechaFin}
                  onChange={handleChangeFechaFin}
                />
              </div>
            </div>
            <div className="wrap-btn-pop">
              <button
                type="button"
                className="new-button-eliminar-filtro"
                onClick={handleButtonLimpiarFiltrosFecha}
              >
                Eliminar el filtro
              </button>
              <input
                type="button"
                value="APLICAR"
                data-wait="Please wait..."
                id="form-fecha-submit"
                className="btn-blue pop w-button new-button"
                onClick={handleButtonFechaRango}
              />
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiltroFecha
