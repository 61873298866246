import moment from "moment";
import "moment/locale/es";
moment.locale("es");

const ordenarEventos = (eventos) => {
  const hoy = moment(new Date());

  return [...eventos]
    .map((evento) => ({
      evento,
      fechas: evento.Fechas.filter((f) =>
        f.Fecha
          ? moment(f.Fecha).isSameOrAfter(hoy, "day")
          : hoy.isBetween(moment(f.Inicio), moment(f.Fin), "day", "[]") ||
            moment(f.Inicio).isSameOrAfter(hoy, "day")
      ).sort((a, b) => {
        const duracionA = a.Fecha
          ? moment.duration(moment(a.Fecha).diff(hoy))
          : moment.duration(moment(a.Fin).diff(moment(a.Inicio)));
        const duracionB = b.Fecha
          ? moment.duration(moment(b.Fecha).diff(hoy))
          : moment.duration(moment(b.Fin).diff(moment(b.Inicio)));

        return duracionA.asMilliseconds() - duracionB.asMilliseconds();
      }),
    }))
    .sort((a, b) => {
      if (!a.fechas.length || !b.fechas.length) return 0;

      const [af] = a.fechas;
      const [bf] = b.fechas;

      if (af.Fecha && moment(af.Fecha).isSame(hoy, "day")) return -1;
      if (bf.Fecha && moment(bf.Fecha).isSame(hoy, "day")) return 1;

      if (hoy.isBetween(moment(af.Inicio), moment(af.Fin), "day", "[]"))
        return -1;
      if (hoy.isBetween(moment(bf.Inicio), moment(bf.Fin), "day", "[]"))
        return 1;

      const duracionA = af.Fecha
        ? moment.duration(moment(af.Fecha).diff(hoy))
        : moment.duration(moment(af.Fin).diff(moment(af.Inicio)));
      const duracionB = bf.Fecha
        ? moment.duration(moment(bf.Fecha).diff(hoy))
        : moment.duration(moment(bf.Fin).diff(moment(bf.Inicio)));

      return duracionA.asMilliseconds() - duracionB.asMilliseconds();
    });
};

export default ordenarEventos;
