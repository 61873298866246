import React from "react"

const FiltroArea = ({
  state,
  setState,
  areasTematicas,
  filtradoCruzado,
  eventos,
  popUp,
  setPopUp,
}) => {
  const handleClickAreaMenu = () => {
    setPopUp(s => ({
      fecha: false,
      tipo: false,
      area: !s.area,
    }))
  }

  const HandleButtonArea = value => {
    let newAreaSeleccionados
    const existe = state.areasSeleccionadas.find(x => x === value)
    if (existe) {
      newAreaSeleccionados = state.areasSeleccionadas.filter(x => x !== value)
    } else {
      newAreaSeleccionados = [...state.areasSeleccionadas, value]
    }
    setState(s => ({ ...s, areasSeleccionadas: newAreaSeleccionados }))
  }

  const handleButtonLimpiarFiltrosArea = () => {
    let aux = {
      ...state,
      filtradoXArea: [],
      areasSeleccionadas: [],
    }

    filtradoCruzado(aux)
  }

  const handleClickAplicarArea = () => {
    let newEventos = []

    if (state.areasSeleccionadas.length === 0) {
      let aux = {
        ...state,
        filtradoXArea: [],
      }

      filtradoCruzado(aux)
    } else {
      eventos.forEach(evento => {
        evento.area_tematicas.forEach(area => {
          if (state.areasSeleccionadas.includes(area.Nombre)) {
            const existe = newEventos.find(n => n.id === evento.id)
            if (!existe) {
              newEventos.push(evento)
            }
          }
        })
      })

      let aux = {
        ...state,
        eventos: [...newEventos],
        filtradoXArea: [...newEventos],
      }

      filtradoCruzado(aux)
    }
  }

  return (
    <div className="wrap-filter-fecha">
      <button
        data-w-id="6a608612-6999-814a-69ad-fabdfae7b8c1"
        className="btn-filter-home area w-button new-button"
        style={
          popUp
            ? {
                borderColor: "rgb(230, 231, 238)",
                backgroundColor: "rgb(230, 231, 238)",
              }
            : {}
        }
        onClick={handleClickAreaMenu}
      >
        área
      </button>
      <div
        className="pop-filter right"
        style={popUp ? { opacity: 1, display: "block" } : {}}
      >
        <div className="w-form">
          <form id="email-form-2" name="email-form-2" data-name="Email Form 2">
            <div className="wrap-pop-tag">
              {areasTematicas.map(area => (
                <button
                  type="button"
                  className="link-tag new-button active"
                  key={area.id}
                  onClick={() => HandleButtonArea(area.Nombre)}
                  style={
                    state.areasSeleccionadas.includes(area.Nombre)
                      ? {
                          backgroundColor: "rgba(35, 101, 255, 0.2)",
                          color: "#1644e5",
                          fontWeight: 700,
                        }
                      : {}
                  }
                >
                  {area.Nombre}
                </button>
              ))}
            </div>
            <div className="wrap-btn-pop">
              <button
                type="button"
                className="new-button-eliminar-filtro"
                onClick={handleButtonLimpiarFiltrosArea}
              >
                Eliminar el filtro
              </button>
              <input
                type="button"
                value="APLICAR"
                data-wait="Please wait..."
                id="form-area-submit"
                className="btn-blue pop w-button new-button"
                onClick={handleClickAplicarArea}
              />
            </div>
          </form>
          <div className="w-form-done">
            <div>Thank you! Your submission has been received!</div>
          </div>
          <div className="w-form-fail">
            <div>Oops! Something went wrong while submitting the form.</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FiltroArea
