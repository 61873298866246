import React from "react"
import "react-responsive-carousel/lib/styles/carousel.min.css" // requires a loader
import { Carousel } from "react-responsive-carousel"
import Slider from "./slider"
import ordenarEventos from "../../helpers/ordenarEventos"

const CarouselDestacado = ({ eventos, variablesEntorno }) => {
  const eventosDestacados = ordenarEventos(eventos)
    .map(e => e.evento)
    .filter(evento => evento.EventoDestacado)

  return (
    <Carousel showArrows={true} infiniteLoop={true} showThumbs={false}>
      {eventosDestacados.map(evento => (
        <div key={evento.id}>
          <Slider {...evento} variablesEntorno={variablesEntorno} />
        </div>
      ))}
    </Carousel>
  )
}

export default CarouselDestacado
