import React from "react";
import IDat16 from "../../images/i-dat-16.svg";
import ILocation16 from "../../images/i-location-16.svg";
import moment from "moment";
import { navigate } from "@reach/router";
import "moment/locale/es";
import { Link } from "gatsby";

export const EventoCard = ({ evento, fechas, variablesEntorno }) => {
  moment.locale("es");

  const { Titulo, Lugar, ImagenDestacada , slug } = evento;
  const proximaFecha = fechas.length > 0 ? fechas[0] : null;

  
  const handleNavigate = () => {
    navigate(`/evento/${slug}`);
  };
  // cag 20230210 
  const ImagenPorDefecto = (ImagenDestacada)?null: {url: `${process.env.GATSBY_AGENDA_IMAGE_DEFAULT}`};
  console.log("dentro event card ImagenDestacada : " + JSON.stringify(ImagenPorDefecto));

  return (
    <div className="event-container">
      <Link
        to={`/evento/${slug}`}
        className="event-card w-inline-block new-button"
        // onClick={handleNavigate}
      >
        <div className="img-event-container">
        {ImagenPorDefecto && (
            <img
              alt={Titulo}
              src={`${variablesEntorno.AGENDA_APP_SITE + ImagenPorDefecto.url}`}
              className="img-event"
            />
          )}
          {ImagenDestacada && (
            <img
              alt={Titulo}
              src={`${variablesEntorno.AGENDA_APP_SITE + ImagenDestacada.url}`}
              className="img-event"
            />
          )}
          {proximaFecha && proximaFecha.Fecha ? (
            <div className="date-event-container">
              <div className="date-number">
                {moment(proximaFecha.Fecha).format("DD")}
              </div>
              <div className="date-month">
                {moment(proximaFecha.Fecha).format("MMM")}
              </div>
            </div>
          ) : (
            <div className="date-event-container">
              <div className="date-number">
                {moment(proximaFecha.Inicio).format("DD")}
              </div>
              <div className="date-month">
                {moment(proximaFecha.Inicio).format("MMM")}
              </div>
            </div>
          )}
        </div>
        <div className="info-event-container">
          <h3 className="h3-card-event">{Titulo}</h3>
          <div className="cont-flex-info-event">
            <div className="icon-wrapper-16">
              <img src={IDat16} loading="lazy" alt="Date" />
            </div>
            {proximaFecha && proximaFecha.Fecha ? (
              <div className="text-block">
                {`${moment(proximaFecha.Fecha).format(
                  "D [de] MMMM [del] YYYY"
                )}, de ${moment(
                  moment(proximaFecha.Fecha).format("YYYY-MM-DD ") +
                    proximaFecha.Inicio
                ).format("h:mm a")} a ${moment(
                  moment(proximaFecha.Fecha).format("YYYY-MM-DD ") +
                    proximaFecha.Fin
                ).format("h:mm a")}`}
              </div>
            ) : (
              <div className="text-block">
                {`Del ${moment(proximaFecha.Inicio).format(
                  "D [de] MMMM [del] YYYY"
                )} al ${moment(proximaFecha.Fin).format(
                  "D [de] MMMM [del] YYYY"
                )}`}
              </div>
            )}
          </div>
          {Lugar.map((l, index) => {
            if (l._xcomponent === "lugar.externo") {
              return (
                l.Ubicacion && (
                  <div key={index} className="cont-flex-info-event">
                    <div className="icon-wrapper-16">
                      <img src={ILocation16} loading="lazy" alt="Location" />
                    </div>
                    <div className="text-block">{l.Ubicacion}</div>
                  </div>
                )
              );
            } else if (l._xcomponent === "lugar.google-maps") {
              return (
                l.DescripcionLugar && (
                  <div key={index} className="cont-flex-info-event">
                    <div className="icon-wrapper-16">
                      <img src={ILocation16} loading="lazy" alt="Location" />
                    </div>
                    <div className="text-block">{l.DescripcionLugar}</div>
                  </div>
                )
              );
            } else {
              return (
                <div key={index} className="cont-flex-info-event">
                  <div className="icon-wrapper-16">
                    <img src={ILocation16} loading="lazy" alt="Location" />
                  </div>
                  <div className="text-block">
                    {l.agenda_master_lugar_pucp?.Nombre}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </Link>
    </div>
  );
};