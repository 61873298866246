import React from "react"
import moment from "moment"
import { navigate } from "@reach/router"
import "moment/locale/es"

const Slider = ({
  Titulo,
  ImagenDestacada,
  Fechas,
  variablesEntorno,
  slug,
}) => {
  moment.locale("es")

  const fechaFormat = moment(
    Fechas[0].Fecha
      ? Fechas[0].Fecha
      : Fechas[0].Inicio
      ? Fechas[0].Inicio
      : new Date()
  ).format("D [de] MMMM [del] YYYY")

  const handleNavigate = () => {
    navigate(`/evento/${slug}`)
  }
  const ImagenPorDefecto = (ImagenDestacada)?null: {url: `${process.env.GATSBY_AGENDA_IMAGE_DEFAULT}`};
  console.log("dentro event card ImagenDestacada : " + JSON.stringify(ImagenPorDefecto));


  return (
    <div id="section-slider">
      <div className="container-transparent">
        <h1 className="h1-tag-home">Agenda PUCP</h1>
      </div>
      <div className="slider-wrapper">
        {/* <div id="arrow-left" className="arrow"></div>
        <div id="arrow-right" className="arrow right"></div> */}
        <div className="div-slide">
          <div className="div-50 slider-home w-clearfix">
            <div className="cont-slider-text">
              <div className="date-slider-home">{fechaFormat}</div>
              <h2 className="heading h2-slider-home">{Titulo}</h2>
              <button
                className="btn-blue w-button new-button"
                onClick={handleNavigate}
              >
                más información
              </button>
            </div>
          </div>
          <div className="div-50 img-slider-full">
          {ImagenPorDefecto && (
              <img
                alt={`imagen ${Titulo}`}
                src={`${
                  variablesEntorno.AGENDA_APP_SITE + ImagenPorDefecto.url
                }`}
                className="div-50 img-slider-full"
              />
            )}
            {ImagenDestacada && (
              <img
                alt={`imagen ${Titulo}`}
                src={`${
                  variablesEntorno.AGENDA_APP_SITE + ImagenDestacada.url
                }`}
                className="div-50 img-slider-full"
              />
            )}
          </div>
        </div>
        {/* <div className="slider-nav-wrap">
          <div className="circle-nav"></div>
          <div className="circle-nav"></div>
        </div> */}
      </div>
    </div>
  )
}

export default Slider